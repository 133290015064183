<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="1"></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">
        <div class="row">
          <div class="col lead">

            <h3>Documentation</h3>

            <p>CloudDFM uses a revolutionary yet simple approach for building powerful online manufacturing solutions. Our goal was to make the API so easy, anyone without web experience could build a complete interface to CloudDFM within an hour or less.</p>

            <p>All you need is to understand our <a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/index.html" target="_blank">Matrix File format</a>, and have the ability to upload a zipped file... really that's it! </p>

            <p>Of course, if you wish to get fancy and integrate CloudDFM within various modern web frameworks, 3rd party API's, or mobile apps there will be additional steps -- however we've tried to make it as simple as possible.</p>

            <h5 class="mt-4">3 Steps to Successfully Use CloudDFM:</h5>

            <ol>
              <li>Basic understanding how to create a Matrix File.</li>
              <li>Ability to zip your CAM files (Gerber, Drill, ODB++, DXF, etc.) along with a matrix file included in the top-level folder of the zip.  We call this type of zipped file a <a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/matrix-blob.html" target="_blank">Matrix Blob.</a></li>
              <li>Wait for CloudDFM to finish, and download the resulting zip file.</li>
            </ol>

            <h5 class="mt-4">What is the Matrix File?</h5>

            <p>It's not based off a '90s movie, but rather the Matrix file is a collection of all possible commands and capabilities grouped into a single organized text file.  Yes, the matrix file is simply a text file that can be edited with any text editor.  We decided to use text files for the Matrix format, because text files are easy for everyone to understand, and portable across every OS platform.  All Matrix Files must end with the file extension (*.fm6)</p>

            <p><b>Note:</b> Matrix files can also be inserted into other matrix files, to form a hierarchy and provide extremely dynamic capabilities -- while keeping the files small, and eliminating redundancies.</p>

            <p>The Matrix file format is updated and maintained by <a href="https://www.numericalinnovations.com" target="_blank">Numerical Innovations, Inc.</a>  Here's a description of the <a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/index.html" target="_blank">Matrix File.</a></p>

            <h5 class="mt-4">What is a Matrix Blob?</h5>

            <p>A Matrix Blob is a single zipped file, which contains a matrix file (*.fm6) in the top-level folder plus all supporting CAM files (Gerber, Drill, ODB++, DXF, etc.). The Matrix blob is primarily used to transfer everything required to complete a task, over the web in single contained file.</p>

            <ol>
              <li>A Matrix file named <b>matrix.fm6</b>, must be in the top-level folder of every Matrix Blob.</li>
              <li>All files referenced in the matrix file must be relative to the top-level path.</li>
              <li>A Matrix Blob can have unlimited sub folders to contain CAM files.</li>
            </ol>

            <p>Here's a few sample Matrix Blobs which you may review.  Try unzipping each blob and view the matrix file <b>matrix.fm6</b>.  Notice how all files are referenced using relative paths in the matrix file.</p>

            <ul>
              <li><a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/example-matrix-1.html" target="_blank">Import Gerber and Drill files, then output Images.</a></li>
              <li><a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/example-matrix-2.html" target="_blank">Output Realistic Photo Images of PCB bareboard.</a></li>
              <li><a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/example-matrix-4.html" target="_blank">Run a DFM Check and output both Text and PDF Reports.</a></li>
              <li><a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/example-matrix-5.html" target="_blank">Panelize and Merge with 3 different PCB Designs.</a></li>
            </ul>

            <h5 class="mt-4">What is the resulting file CloudDFM generates?</h5>

            <p>After processing your matrix blob, CloudDFM will return a zipped file which contains a JSON file always named <b>cam_output.json</b> in the top-level folder.  This JSON file is basically a map which describes and pinpoints the locations of any generated files (as instructed by the MATRIX file).  Here's more information about <a href="https://numerical-help-guide.s3.amazonaws.com/matrixfile/html/matrix-clouddfm-output.html" target="_blank">the resulting file CloudDFM generates.</a></p>

            <hr class="mt-5 mb-5"/>

            <h5 class="mt-4">Okay, Ready to get Started with CloudDFM?</h5>
            <p>First you'll need to <a href="/landing/signup">Signup</a> and create a CloudDFM account.  The free HOBBY plan is the default.</p>

            <hr class="mt-5 mb-5"/>

            <h5 class="mt-4">Manually using CloudDFM</h5>

            <ol>
              <li><a href="/landing/login">Login</a> to CloudDFM using your account, and you will be taken to the Dashboard.</li>
              <li>Press the button "New Job", and select your matrix blob file.  If you haven't created a matrix blob yet, use one of the samples matrix blobs provided (above).</li>
              <li>Wait until CloudDFM has finished processing your Matrix Blob.</li>
              <li>Download and Unzip the CloudDFM generated file.</li>
            </ol>

            <p>For some users that's all they need.  Write a matrix file which tells CloudDFM what you want done, download and use the resulting files.  However many users will want to automate this entire process: Create and upload the Matrix Blob, process job, download resulting file, and parse the results to suit your requirements -- all done by machine without any human interaction.</p>

            <hr class="mt-5 mb-5"/>

            <h5 class="mt-4">Automating CloudDFM</h5>

            <p>
              You can fully automate and integrate CloudDFM using webhooks.
              With webhooks you receive "real-time" notifications (i.e callbacks) from CloudDFM when jobs have completed.  Once a notification is received from CloudDFM, you can then run any processes you had in your application.
              For some basic examples visit our <a href="https://github.com/NumericalInnovations/clouddfm-examples">GitHub</a> repository.
            </p>

            <hr class="mt-5 mb-5"/>
            <h5 class="mt-4">Visualize and Test Matrix Files</h5>
            <p>To help visualize and test matrix files you generate, try using our desktop software: <a href="https://www.numericalinnovations.com/collections/fab-3000-gerber-cam">FAB 3000</a> or <a href="https://www.numericalinnovations.com/pages/dfm-now-free-gerber-viewer">DFM Now (Free)</a></p>


            <hr class="mt-5 mb-5"/>
            <h5 class="mt-4">Need More Assistance?</h5>
            <ul>
              <li><a href="https://support.numericalinnovations.com/support/tickets/new" target="_blank">Submit a Support Ticket for Immediate On-Line Help</a></li>
              <li><a href="https://support.numericalinnovations.com/support/discussions/forums/14000131270" target="_blank">Visit CloudDFM Help Center</a></li>
            </ul>

          </div>
        </div>
      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';

  export default {

    components: {PageHeader,PageFooter},

    mounted() {
      app = this;
      document.title = 'Documentation';
    }

  }
</script>
