<template>
  <main role="main">

    <div style="flex:1 1 auto">


      <!-- Navigation -->
      <page-header nav="0"></page-header>
      <!-- Navigation -->

      <!-- Masthead -->
      <header class="masthead text-white text-center">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-10 mx-auto">
              <h1>Build Manufacturing Apps in the Cloud!</h1>
              <p>Easy to Use. Low Cost. Secure and Reliable.</p>
            </div>
          </div>
        </div>
      </header>
      <!-- Masthead -->

      <!-- Body -->
      <section class="showcase py-1">
        <div class="container py-5">
          <div class="row">

            <div class="col-12 col-lg-7">
              <p><h5>What is CloudDFM?</h5></p>
              <p class="lead mb-3">An Easy to use <a href="https://en.wikipedia.org/wiki/Web_API" target="_blank">Web API</a> empowering anyone who wants to develop next-generation electronic manufacturing apps in the Cloud.  CloudDFM easily integrates with all popular Web Frameworks, iOS, Android.</p>
              <p class="mt-4"><h5>What is DFM?</h5></p>
              <p class="lead mb-3">DFM is <a href="https://en.wikipedia.org/wiki/Design_for_manufacturability" target="_blank">Design for Manufacturability.</a>  CloudDFM ensures all designs are ready for successful manufacturing by providing 85+ essential DFM Checks & more.</p>
              <p class="mt-4"><h5>Why use CloudDFM?</h5></p>
              <p class="lead mb-3">CloudDFM will lower manufacturing costs, improve product quality, and promote faster delivery!  Our engineering team has 25+ years successfully developing high-quality EDA/CAD/3D software trusted by industry leaders such as Apple, Google, Intel, and Rolls-Royce.</p>
            </div>

            <div class="col-12 col-lg-5 mt-4 mt-lg-0">
              <div class="card card-body text-dark d-flex justify-content-center align-items-center text-center" style="font-size:1.15em;background-color:#eaeaff">
                <div class="px-4 pt-3 pb-4 rounded">
                  <p class="p-1 m-0"><b>Built with CloudDFM:</b></p>
                  <p class="p-1 m-0">Online DFM Checkers</p>
                  <p class="p-1 m-0">Online CAD Converters</p>
                  <p class="p-1 m-0">Online Gerber/ODB++ Viewers</p>
                  <p class="p-1 m-0">Online Netlist Comparison</p>
                  <p class="p-1 m-0">Online PCB Panelization</p>
                  <p class="p-1 m-0">Compare Design Changes</p>
                  <p class="p-1 m-0">Instant PCB Quotes</p>
                  <p class="p-1 m-0">And More!</p>
                  <a class="btn btn-lg btn-primary mt-3 text-white" @click="signupContact" href="javascript:;">Sign Up</a>
                  <div class="mt-3">
                    <router-link :to="{name:'Login'}" tag="a" class="text-primary">Already a member? Click here.</router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <!-- Body -->


      <!-- Icons Grid -->
      <section class="features-icons bg-light text-center">
        <div class="container">
          <div class="row">
            <div class="col-4">
              <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div class="features-icons-icon d-flex">
                  <i class="icon-rocket m-auto text-primary"></i>
                </div>
                <h3>Fast & Efficient</h3>
                <p class="lead mb-0">Online cloud processing saves money and is more efficient.</p>
              </div>
            </div>
            <div class="col-4">
              <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                <div class="features-icons-icon d-flex">
                  <i class="icon-organization m-auto text-primary"></i>
                </div>
                <h3>Import and Export</h3>
                <p class="lead mb-0">Full support for various EDA formats: Gerber, NC Drill/Mill, ODB++, DXF, GDS-II, and more.</p>
              </div>
            </div>
            <div class="col-4">
              <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div class="features-icons-icon d-flex">
                  <i class="icon-badge m-auto text-primary"></i>
                </div>
                <h3>DFM Check Verification</h3>
                <p class="lead mb-0">Ensure PCB's are built correctly the first time!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Icons Grid -->

      <!-- Call to Action -->
      <section class="call-to-action text-white text-center">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-9 mx-auto">
              <h2 class="mb-4">Ready to Sign Up?</h2>
            </div>
            <div class="col-12">
              <a class="btn btn-lg btn-primary mt-1" href="javascript:;" @click="signupContact">Sign Up</a>
            </div>
          </div>
        </div>
      </section>
      <!-- Call to Action -->

    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>


<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {
      PageHeader,
      PageFooter
    },

    mounted() {
      app = this;
      document.title = "Cloud DFM";
    },

  }
</script>
