<template>
  <div>


    <!-- FOOTER -->
    <footer class="footer mt-auto bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-center text-lg-left">
            <ul class="list-inline mb-2">
              <li class="list-inline-item">
                <router-link :to="{name:'About'}" tag="a" class="text-primary">About</router-link>
              </li>
              <li class="list-inline-item">&sdot;</li>
              <li class="list-inline-item">
                <router-link :to="{name:'Contact'}" tag="a" class="text-primary">Contact</router-link>
              </li>
              <li class="list-inline-item">&sdot;</li>
              <li class="list-inline-item">
                <router-link :to="{name:'Terms'}" tag="a" class="text-primary">Terms of Use</router-link>
              </li>
              <li class="list-inline-item">&sdot;</li>
              <li class="list-inline-item">
                <router-link :to="{name:'Privacy'}" tag="a" class="text-primary">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 text-center text-lg-right">
            <p class="text-muted mb-3 mb-lg-0">&copy; 2023 Numerical Innovations Inc.</p>
            <p>
              <a target="_blank" href="https://www.youtube.com/channel/UCAvqKXiCvKkcGreKlSs8S7Q" class="mr-2 text-primary"><i class="fab fa-youtube"></i></a>
              <a target="_blank" href="https://www.facebook.com/Cloud-DFM-114136733702015" class="mr-2 text-primary"><i class="fab fa-facebook-square"></i></a>
              <a target="_blank" href="https://twitter.com/clouddfm1" class="text-primary"><i class="fab fa-twitter"></i></a>
            </p>
          </div>
        </div>
      </div>
    </footer>
    <!-- FOOTER -->


  </div>
</template>

<script>
  import Common from '../mixins/Common'

  export default {
    mixins: [Common]
  }
</script>
