<template>
  <div id="app">
    <ok-modal></ok-modal>
    <confirm-modal></confirm-modal>
    <spinner image="/images/landing/spinner.png"></spinner>
    <router-view/>
  </div>
</template>

<script>
  //CSS global files
  //require('../public/vendor/bootstrap/css/bootstrap.min.css');
  require('../public/vendor/fontwawesome/css/all.min.css');
  require('../public/vendor/lightbox/css/lightbox.min.css');
  require('../public/vendor/simple-line-icons/css/simple-line-icons.css');

  import './App.scss';
  import 'bootstrap-vue/dist/bootstrap-vue.css'
  import storePlugin from './storePlugin'
  import Spinner from './components/Spinner.vue'
  import OkModal from './components/OkModal.vue'
  import ConfirmModal from './components/ConfirmModal.vue'
  import Vue from 'vue'
  import VueI18n from 'vue-i18n'
  import VueAnalytics from 'vue-analytics'
  import router from './router'
  import {BootstrapVue} from 'bootstrap-vue'

  Vue.use(storePlugin);
  Vue.use(VueI18n);
  Vue.use(BootstrapVue);

  //Global event hub
  Vue.prototype.$eventHub = new Vue();

  export default {

    name: 'App',

    components: {
      OkModal,
      ConfirmModal,
      Spinner
    },

    created() {
      app = this;

      //Clear querystring
      //window.history.replaceState("object or string", "Title", window.location.href.split(/[?#]/)[0]);

      //Store server data
      let n = JSON.parse(Buffer.from(DATA,'base64').toString());
      this.$store.commit('spread',n);
      //console.log(n.app_name+" LANDING");
      //console.log("DATA = "+JSON.stringify(n));
    },

    mounted() {
      $('.modal').modal('hide');

      //Google Analytics
      //console.log("Google Analytics ID = "+GA_ID);
      Vue.use(VueAnalytics, {id:[GA_ID], router});
    },

    methods: {
    }

  }
</script>
