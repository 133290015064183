<template>
  <div id="confirmModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{this.title}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p v-html="this.message"></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="yesClicked">{{this.yes}}</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{this.no}}</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  export default {

    data() {
      return {
        title: '',
        message: '',
        yes: 'Yes',
        no: 'No',
        callback: null
      }
    },

    mounted() {
      this.$eventHub.$on('confirm-modal',obj => {
        this.title = obj.title;
        this.message = obj.message;
        this.callback = obj.callback;
        if(obj.yes!=null) this.yes = obj.yes;
        if(obj.no!=null) this.no = obj.no;
        this.$nextTick(()=>{$('#confirmModal').modal('show')});
      })
      this.$eventHub.$on('confirm-modal-close',() => {
        $('#confirmModal').modal('hide');
      })
    },

    methods: {

      yesClicked() { //Generic confirm modal YES
        $('#confirmModal').modal('hide');
        if(this.callback!=null) {
          this.callback();
          this.callback = null;
        }
      }

    }

  }
</script>
