<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="5"></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container mt-5">
        <div class="row">
          <div class="col">

            <!--WIZARD NAV-->
            <div class="d-flex justify-content-between mb-3">
              <div>
                <h3>DFM Check Demo</h3>
                <p class="lead mb-3">Demonstrates how easily any company can now offer instant DFM Checks with Price Quotations to their customers using Cloud DFM.  Cloud DFM can easily be embedded into your company website and social media platforms within 30 minutes of less!</p>
                <p class="lead mb-3">Attn PCB fabricators: This simple feature can immediately improve your customers online engagement, and streamlines your entire Sales/RFQ process.</p>
                <p class="lead mb-3">Demo Limits:  Max file size 5MB.  Only generic predefined DFM rules are used in this demo.  Registered CloudDFM users do not have any limitations!</p>
              </div>
              <!--
              <div>
                <button type="button" class="btn btn-primary" @click="onReset">Reset</button>
              </div>
              -->
            </div>
            <!--WIZARD NAV-->


            <!-- STEPS -->
            <div class="mb-2">
              <div class="d-inline-flex">
                <div class="flex-1 bg-primary text-white text-center flex-shrink-0 align-items-start" style="width:28px;height:28px;border-radius:14px;font-weight:bold;font-size:1.20em">1</div>
                <div class="flex-grow-1 pl-2 align-items-center" style="font-size:1em"><span>Upload your PCB Design files (Gerber, NC Drill/Rout, IPC-2581, or ODB++ files)</span></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-inline-flex">
                <div class="bg-primary text-white text-center flex-shrink-0 align-items-start" style="width:28px;height:28px;border-radius:14px;font-weight:bold;font-size:1.20em">2</div>
                <div class="flex-grow-1 pl-2 align-items-center" style="font-size:1em"><span>Enter your email address and confirm.</span></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-inline-flex">
                <div class="bg-primary text-white text-center flex-shrink-0 align-items-start" style="width:28px;height:28px;border-radius:14px;font-weight:bold;font-size:1.20em">3</div>
                <div class="flex-grow-1 pl-2 align-items-center" style="font-size:1em"><span>CloudDFM will generate and email you a detailed DFM Check report!</span></div>
              </div>
            </div>
            <!-- STEPS -->


            <!-- INTERACT-->
            <div class="py-3 px-4 bg-light border rounded mt-5">
              <b-container>
                <b-row class="my-1">
                  <b-col sm="3" class="p-1 d-flex align-items-center">
                    <b>Design Files</b>
                  </b-col>
                  <b-col sm="9" class="p-1">
                    <b-form-file id="files" v-model="files" placeholder="Choose multiple files or drop it here..." drop-placeholder="Drop multiple files here..." required multiple></b-form-file>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="3" class="p-1 d-flex align-items-center">
                    <b>Your Email</b>
                  </b-col>
                  <b-col sm="9" class="p-1">
                    <b-form-input v-model="email" type="email" placeholder="Enter your email"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="3" class="p-1 d-flex align-items-center">
                    <b>Confirm Email</b>
                  </b-col>
                  <b-col sm="9" class="p-1">
                    <b-form-input v-model="confirmEmail" type="email" placeholder="Confirm your email"></b-form-input>
                </b-row>
                <b-row>
                  <b-col class="text-right p-1 mt-2">
                    <button class="btn btn-primary btn-lg" @click="onProcess">Process</button>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <!-- INTERACT-->

          </div>
        </div>
      </div>
      <!-- Body -->

    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {PageHeader,PageFooter},

    data() {
      return {
        files: null,
        email: "",
        confirmEmail: ""
      }
    },

    mounted() {
      app = this;
      document.title = 'Live Demo';
    },

    methods: {

      onProcess() {
        if(this.files==null || this.files.length==0) return this.$eventHub.$emit('ok-modal',{title:"Files Required",message:"Please choose one or more files."});

        if(this.email.length==0) return this.$eventHub.$emit('ok-modal',{title:"Email Required",message:"Please enter your email to receive the results."});

        if(this.confirmEmail!=this.email) return this.$eventHub.$emit('ok-modal',{title:"Email Mismatch",message:"The emails you entered must match."});

        if(this.validateEmail(this.email)==false) return this.$eventHub.$emit('ok-modal',{title:"Email Invalid",message:"Please enter a valid email to receive the results."});

        let fd = new FormData();
        for(let i=0;i<this.files.length;i++) fd.append('files',this.files[i]);
        fd.append('email',this.email);
        fd.append('timeout',5);

        app.$eventHub.$emit('loading',true);
        this.result = null;
        this.$forceUpdate();
        let url = this.getApiUrl();
        $.ajax({
          url: `${url}/api/v1/job/demo`,
          type: 'POST',
          data: fd,
          cache: false,
          contentType: false,
          processData: false,
          xhr: () => {
            let myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener('progress', function (e) {
                if (e.lengthComputable) console.log(`Uploading ${e.loaded}/${e.total}`);
              }, false);
            }
            return myXhr;
          },
          error: (err) => {
            console.log(err);
            this.$eventHub.$emit('loading',false);
            return this.$eventHub.$emit('ok-modal',{title:"Error",message:err.statusText});
          }
        }).done(response => {
          app.$eventHub.$emit('loading',false);
          if(response.ok==0) {
            this.$eventHub.$emit('ok-modal',response.err);
          }
          else {
            this.$eventHub.$emit('ok-modal',{title:"DFM Check Processing",message:"Your results will be emailed to you shortly."});
          }
        });

      }

    }
  }
</script>
