<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">

        <div class="row">
          <div class="col">
            <h3>Contact</h3>
            <p class="lead mb-4">
              Please leave us a message if you need more information about our services.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-5">
            <div class="form-group">
              <label for="name"><b>Your Name</b></label>
              <input id="form-name" type="name" class="form-control" placeholder="Your Name" max-length="100" v-model="name">
            </div>
            <div class="form-group">
              <label for="email"><b>Your Email Address</b></label>
              <input id="form-email" type="email" class="form-control" placeholder="Your Email Address" max-length="128" v-model="email">
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div class="form-group">
              <label for="message"><b>Your Message</b></label>
              <textarea style="height:124px" id="form-message" class="form-control" placeholder="Your Message" rows="5" max-length="1024" v-model="message"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <a class="btn btn-lg btn-primary text-white text-left mt-3" href="javascript:;" @click="submit">
               <div style="display:inline-block;width:24px;margin-right:8px;text-align:center"><i class="fas fa-envelope"></i></div>
               Send Message
            </a>
            <br/>
          </div>
        </div>


      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {PageHeader,PageFooter},

    data() {
      return {
        name: "",
        email: "",
        message: ""
      }
    },

    mounted() {
      app = this;
      document.title = 'Contact';
    },

    methods: {

      submit() {
        if(this.name.length==0) return this.$eventHub.$emit('ok-modal',{title:"Invalid Name",message:"Please enter your real name."})
        else if(this.email.length==0) return this.$eventHub.$emit('ok-modal',{title:"Invalid Email",message:"Please enter your real email so we can get back to you.."})
        else if(this.message.length==0) return this.$eventHub.$emit('ok-modal',{title:"Invalid Message",message:"Please enter a message so we can understand the situation."})
        else if(this.message.length<10) return this.$eventHub.$emit('ok-modal',{title:"Invalid Message",message:"Please enter a message equal or longer than 10 characters."})

        let cb = () => {
          this.$router.push({name:'Main'});
        }

        this.$eventHub.$emit('loading',true);
        let url = this.getApiUrl();
        let params = {name:this.name,email:this.email,message:this.message}
        $.post(`${url}/api/v1/contact`,params).done(response => {
          this.$eventHub.$emit('loading',false);
          if(response.ok==0) return this.$eventHub.$emit('ok-modal',response.err);
          return this.$eventHub.$emit("ok-modal",{title:"Message Sent",message:"Your message has been sucessfully sent.  Please give us 24 hours to respond back to your email.  Thanks!",callback:cb})
        });
      }

    }

  }
</script>
