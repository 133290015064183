<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">
        <div class="row">
          <div class="col lead">
            <h3>Privacy Policy</h3>
            <p>CloudDFM can only exist if our customers can be sure that their data is safe. Thus, privacy has the highest priority for us.</p>

            <p class="mb-4"><h5>Cookies</h5></p>
            <p>CloudDFM stores cookies in order to be able to offer you a comprehensive range of functions and to make it easier to use our websites. Cookies are small files which are stored on your computer with the help of the internet browser. If you do not want to use cookies, you can prevent cookies from being stored on your computer using the corresponding settings on your internet browser. Please note that this may restrict the functional capability and the range of functions of our offer.</p>

            <p class="mb-4"><h5>Personal Data Collected by Us</h5></p>
            <p>By visiting our website and/or using our API, your IP address will be logged along with the HTTP referrer, user agent and the dates of access (log data).  By providing a file for processing we do not read or collect file content, metadata or other data from your uploaded files. For statistical purposes, we do collect the number of converted files, the number of errors and the total file size of all your conversions only. If you decide to create a CloudDFM account, we collect your e-mail address, your chosen username and your profile picture. If you use our contact form, we collect your e-mail address and your name.  If you decide to buy a subscription, we need to additionally collect your full name, physical address, company name. We do not collect your credit card data. All financial transactions are handled through our payment provider Stripe. Before providing any personal data to Stripe, please make sure that you have read their privacy policies.</p>

            <p class="mb-4"><h5>Use of Your Personal Data</h5></p>
            <p>We do log your IP address to prevent abuse of our service and for broad demographic analysis. We do not link this information to any personally identifiable information.  If you provide an e-mail address we use it to communicate with according to your notification settings.  If you buy a subscription for our services, your name, company and address is used to create the invoice and for prevention of fraud. </p>

            <p class="mb-4"><h5>Handling of Your Files</h5></p>
            <p>In the course of providing the service your files are transferred to and temporary stored on servers of CloudDFM. We do not read, look into or mine any data from your files or its metadata.  All file processing is done by machine and there is no human interaction with your files.</p>

            <p class="mb-4"><h5>Keeping Your Data</h5></p>
            <p>We will keep your personal data for as long as it is necessary to provide you with our services. Your files are deleted immediately and irreversible from our servers when use the delete button.  If you delete your account, all personal data will be permanently deleted within 72 hours. All log data (IP address, user agent, referrer) is deleted after 14 days.</p>

            <p class="mb-4"><h5>Your Rights</h5></p>
            <p>Your can modify your data on your dashboard. You can delete your account at anytime on your own. This will delete your account and all associated data permanently within 72 hours. Deleting your account also deletes all data stored at payment provider Stripe and ticket system Freshdesk (if there is any). Alternatively, you can contact us and we will do it for you.</p>

            <p class="mb-4"><h5>Security</h5></p>
            <p>We are committed to protect your personal data and your files. CloudDFM takes all necessary measures to keep your personal information and your files private and secure.  CloudDFM maintains a secure IT environment and all security measures are constantly adjusted to technical progress.</p>

          </div>
        </div>
      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';

  export default {

    components: {PageHeader,PageFooter},

    mounted() {
      app = this;
      document.title = 'Privacy Policy';
    }

  }
</script>
