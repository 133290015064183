<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="2"></page-header>
      <!-- Navigation -->

      <div class="container mt-5 mb-5">

        <h3 class='mb-4'>CloudDFM Signup</h3>

        <p class="lead mb-4">
          For signup, please contact us directly and we'll be happy to assist you further.
        </p>
        <router-link :to="{name:'Contact'}" type="button" class="btn btn-lg btn-primary" @click="changePlan('hobby')">Contact Us</router-link>

      </div>
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<style>
.subtitle {
  line-height:1.5em;
  color: #6c757d;
}
.card-pricing {
  z-index: 1;
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 16px;
}
.card-pricing.popular {
  border: 2px solid #007bff;
}
.card-pricing .list-unstyled li {
  padding: .4rem 0;
  color: #6c757d;
}
</style>


<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  export default {

    mixins: [Common],

    components: {
      PageHeader,
      PageFooter,
      VueSlider
    },

    data() {
      return {
        money: new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}),
        package_mins: 100,
        package_cost: 0.30
      }
    },

    mounted() {
      app = this;
      document.title = 'Pricing';
    },

    methods: {

      changePlan(plan) {
        this.$router.push({name:'Signup'});
      }

    }

  }
</script>
