//Store for whole app

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    needSave: false,
  },

  getters: {
  },

  mutations: {
    spread (state, data) {
      if(data!=null) Object.assign(state,data);
    }

  }

})
